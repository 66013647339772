import React from 'react'
import FadeLeftAnimation from "../../common/FadeLeftAnimation";

const Banner = (props) => {
  const { title, greenHeader, whiteHeader } = props;
  return (
    <div className="about-banner">
      <div className="container">
        <div className="row align-items-center main-banner-content ">
          <div className="col-lg-12 col-md-12">
            <div className="text-white ">
              <FadeLeftAnimation>
                <h4 className="pb-4">{title}</h4>
              </FadeLeftAnimation>
              <FadeLeftAnimation>
                <h1>
                  <span className="text-primary text-nowrap">{greenHeader}</span>{" "}
                  {whiteHeader}
                </h1>
              </FadeLeftAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner;
