import React from "react";
import Layout from "../components/global/Layout";
import Above from "../components/global/about/AboutAbove";
import { graphql } from "gatsby";
import { parseSeoFromQuery } from "../components/global/SEO";

import Loadable from '@loadable/component'

const Mission = Loadable(() => import("../components/global/about/Mission"))
const Story = Loadable(() => import("../components/global/about/Story"))
const Locations = Loadable(() => import("../components/global/about/Locations"))
const Team = Loadable(() => import("../components/global/about/Team"))
const Board = Loadable(() => import("../components/global/about/Board"))
const Culture = Loadable(() => import("../components/global/about/Culture"))

const About = props => {
  const data = props?.data?.markdownRemark?.frontmatter;
  const imageNodes = props?.data?.allFile?.nodes;
  const imagesArray = [];
  if(data?.aboutLocation) {
      ['uk', 'ch', 'au', 'nl'].forEach( (market) => {
        const imgInfo = imageNodes.find((img) => data?.aboutLocation?.[market].photo.indexOf(img.childImageSharp.fluid.originalName) !== -1);
        if(imgInfo) {
          imagesArray.push({
            market: market,
            info: imgInfo
          })
        }
      })
  }

  return (
    <Layout {...parseSeoFromQuery(props.data)}>
      <Above {...data?.aboutBanner} />
      <Mission {...data?.aboutMission} />
      <Story {...data?.aboutStory} />
      <Locations {...data?.aboutLocation} imagesArray={imagesArray} />
      <Team {...data?.aboutPeople} />
      <Board {...data?.aboutBoard} />
      <Culture {...data?.aboutCulture} />
    </Layout>
  );
};

export const query = graphql`
  query ($page: String, $locale: String) {
    allFile(filter: {childrenImageSharp: {elemMatch: {gatsbyImageData: {ne: ""}}}}) {
      nodes {
          childImageSharp {
            fluid {
              originalImg
              originalName
              src
              srcSet
              sizes
            }
          }        
      }
    }
    markdownRemark(
      frontmatter: { page: { eq: $page }, locale: { eq: $locale } }
    ) {
      frontmatter {
        ...seo
        aboutBanner {
          title
          greenHeader
          whiteHeader
        }
        aboutMission {
          title
          body
        }
        aboutStory {
          greenHeader
          whiteHeader
          body
        }
        aboutLocation {
          header
          body
          selectLocationHeader
          selectLocationBody
          au {
            country
            about
            numberOfEmployees
            employees
            quote
            photo
            marketHeadGreenText
            marketHeadWhiteText
          }
          ch {
            country
            about
            numberOfEmployees
            employees
            quote
            photo
            marketHeadGreenText
            marketHeadWhiteText
          }
          nl {
            country
            about
            numberOfEmployees
            employees
            quote
            photo
            marketHeadGreenText
            marketHeadWhiteText
          }
          uk {
            country
            about
            numberOfEmployees
            employees
            quote
            photo
            marketHeadGreenText
            marketHeadWhiteText
          }
        }
        aboutPeople {
          blackHeader
          greenHeader
          body
          people {
            name
            photo
            position
            email
            phoneNumber
            email
            linkedIn
          }
        }
        aboutBoard {
          blackHeader
          boardMembers {
            name
            photo
            description
            linkedIn
          }
        }
        aboutCulture {
          title
          body
          quotes {
            quote
            author
            position
          }
        }
      }
    }
  }
`;

export default About;
